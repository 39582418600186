// Auth0 imports
import React from "react"
import { silentAuth } from "./src/utils/auth"

// Styles
import "./src/styles/main.css"


// Apollo imports
import { ApolloProvider } from '@apollo/client';
import { client } from './src/apollo/client';

// Auth0
class SessionCheck extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  handleCheckSession = () => {
    this.setState({ loading: false })
  }

  componentDidMount() {
    silentAuth(this.handleCheckSession)
  }

  render() {
    return (
      this.state.loading === false && (
        <React.Fragment>{this.props.children}</React.Fragment>
      )
    )
  }
}

export const wrapRootElement = ({ element }) => {
  return (<ApolloProvider client={client}><SessionCheck>{element}</SessionCheck></ApolloProvider>)
}

import fetch from 'cross-fetch';
import { ApolloClient, InMemoryCache, HttpLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';

// Working
export const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://student-api.dominguezdev.com/v1/graphql',
    headers: {
      "x-hasura-admin-secret": process.env.GATSBY_HASURA_SECRET
    },
    fetch,
  }),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network'
    }
  }
});


// const httpLink = new HttpLink({
//   uri: 'http://localhost:8000'
// });

// const wsLink = new WebSocketLink({
//   uri: `https://comments-v2.hasura.app/v1/graphql`,
//   options: {
//     reconnect: true
//   }
// });

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === 'OperationDefinition' &&
//       definition.operation === 'subscription'
//     );
//   },
//   wsLink,
//   httpLink,
// );